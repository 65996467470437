<template>
  <div class="wifisetingPage" tabindex="1" @keydown="returnFn($event)">
    <div v-show="state === 0" style="height: 85%">
      <div class="wifiTabContent">
        <div
          class="back"
          tabindex="1"
          @click="back('/')"
          @keydown="keyBack($event)"
          ref="back"
        >
          Exit
        </div>
        <div
          class="wifiRefresh"
          tabindex="1"
          @click="scanWifi"
          ref="Refresh"
          @keydown="keyRefresh($event)"
        >
          Refresh
        </div>
      </div>
      <div class="wifisettingBox" v-show="!scaning">
        <ul class="wifiList" ref="wifiList">
          <li
            tabindex="1"
            class="wifiListItem"
            v-for="item in wifiList"
            :key="item.ssid"
            @mousemove="mousemove"
            @keydown="wifiKeyDown($event)"
            @click="click(item.ssid)"
          >
            <span class="wifiName">{{ item.ssid }}</span>
            <span class="isConnect">{{
              $store.state.connectedSSid === item.ssid
                ? "已连接"
                : $store.state.connecting.ssid === item.ssid &&
                  $store.state.connecting.state
                ? "connecting"
                : ""
            }}</span>
            <span class="wifiLog">
              <img class="wifiPng" :src="item.url" align="absmiddle" />
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="disConnect" v-show="state === 2">
      <p id="disConnectwifiName">Disconnect</p>
      <div class="buttonContent">
        <div
          class="btn"
          tabindex="1"
          id="confirm"
          @click="confirmDisconnect()"
          @keydown="disConnectKeyDown($event)"
        >
          Confirm
        </div>
        <div
          class="btn"
          tabindex="1"
          id="cancel"
          @click="cancelConnect()"
          @keydown="disConnectKeyDown($event)"
        >
          Cancel
        </div>
      </div>
    </div>
    <div class="wifiInputBox" v-show="state === 1">
      <p id="wifiName">{{ ssid }}</p>
      <input
        id="pwdInput"
        type="text"
        placeholder="password"
        autocomplete="off"
        @focus="focus($event)"
        @keydown="inputKeyDown($event)"
        v-model="pwd"
      />
      <div id="buttonContent">
        <div
          class="btn"
          tabindex="1"
          @click="confirmConnect()"
          @keydown="connectKeyDown($event)"
        >
          Confirm
        </div>
        <div
          class="btn"
          tabindex="1"
          @click="cancelConnect()"
          @keydown="connectKeyDown($event)"
        >
          Cancel
        </div>
      </div>
    </div>
    <div id="loadingBox" v-show="state === 3">
      <p>SSID: {{ ssid }}</p>
      <p id="loadingNotice">{{ connectTip }}</p>
    </div>
    <div class="scanLoading" v-show="scaning">
      <img :src="loadGif" />
    </div>
  </div>
</template>
<script>
import {
  signalSelect,
  hasSSid,
  getWifiList,
  removeWifiBySSid
} from '@/util/wifiUtil.js'
import { keyMap } from '@/util/keyUtil.js'
export default {
  data () {
    return {
      wifiList: [],
      state: 0,
      scaning: false,
      ssid: '',
      move: false,
      isConnecting: false,
      pwd: '',
      connectTip: 'Connecting...',
      loadGif: require('@/assets/img/loading.gif')
    }
  },
  watch: {
    state: {
      handler: function () {
        if (this.state === 0) {
          this.pwd = ''
          this.$nextTick(() => {
            this.$refs.Refresh.focus()
          })
        } else if (this.state === 2) {
          this.$nextTick(() => {
            document.getElementById('confirm').focus()
          })
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.scanWifi()
  },
  methods: {
    back () {
      this.$router.replace('/setting')
    },
    scanWifi () {
      if (this.scaning) return
      this.scaning = true
      this.$plugin.scanWifi().then((result) => {
        console.log(result)
        this.wifiList = result.map((item) => {
          return {
            ...item,
            url: require('@/assets/picture/wifi_' +
              signalSelect(item) +
              '.png')
          }
        })
        this.scaning = false
      })
    },
    mousemove () {
      this.move = true
    },
    click (ssid) {
      if (this.move) {
        this.move = false
        return
      }
      this.ssid = ssid
      if (this.ssid === this.$store.state.connectedSSid) {
        this.state = 2
      } else {
        const index = hasSSid(this.ssid)
        if (index >= 0) {
          this.pwd = getWifiList()[index].pwd
          this.confirmConnect()
        } else {
          this.state = 1
        }
      }
      this.isConnecting = false
      this.$nextTick(() => {
        document.getElementById('pwdInput').focus()
      })
    },
    focus (e) {
      this.$ime.open(e.target)
    },
    confirmConnect () {
      this.state = 3
      this.$ime.close()
      this.connectTip = 'Connecting...'
      this.$plugin
        .connectWifi(this.ssid, this.pwd)
        .then((result) => {
          this.connectTip = 'connect success'
          setTimeout(() => {
            this.isConnecting = false
            this.state = 0
          }, 2000)
        })
        .catch((e) => {
          if (e.message) {
            this.connectTip = e.message
          } else {
            this.connectTip = 'connect faild'
            removeWifiBySSid(this.ssid)
          }
          setTimeout(() => {
            this.state = 1
          }, 2000)
        })
    },
    cancelConnect () {
      this.$ime.close()
      this.state = 0
    },
    confirmDisconnect () {
      this.$plugin.disconnect()
      this.scanWifi()
      this.state = 0
    },
    keyBack (e) {
      const key = keyMap[e.keyCode]
      const target = e.target
      switch (key) {
        case 'right':
          this.$refs.Refresh.focus()
          break
        case 'down':
          this.listFocus(e)
          break
        case 'ok':
          target.click()
          break
      }
    },
    keyRefresh (e) {
      console.log(e)
      const key = keyMap[e.keyCode]
      const target = e.target
      switch (key) {
        case 'left':
          this.$refs.back.focus()
          break
        case 'down':
          this.listFocus(e)
          break
        case 'ok':
          target.click()
          break
      }
    },
    listFocus (e) {
      e.preventDefault()
      if (this.$refs.wifiList.children.length > 0) {
        this.$refs.wifiList.children[0].focus()
      }
    },
    wifiKeyDown (e) {
      e.preventDefault()
      const target = e.target
      const key = keyMap[e.keyCode]
      switch (key) {
        case 'up':
          if (target.previousSibling) {
            target.previousSibling.focus()
          } else {
            this.$refs.Refresh.focus()
          }
          break
        case 'down':
          if (target.nextSibling) {
            target.nextSibling.focus()
          }
          break
        case 'ok':
          target.click()
          break
      }
    },
    disConnectKeyDown (e) {
      const key = keyMap[e.keyCode]
      const target = e.target
      switch (key) {
        case 'left':
          if (target.previousSibling) {
            target.previousSibling.focus()
          }
          break
        case 'right':
          if (target.nextSibling) {
            target.nextSibling.focus()
          }
          break
        case 'ok':
          target.click()
          break
      }
    },
    connectKeyDown (e) {
      const key = keyMap[e.keyCode]
      const target = e.target
      switch (key) {
        case 'up':
          document.getElementById('pwdInput').focus()
          break
        case 'down':
          console.log('down')
          this.$ime.focus()
          break
        case 'left':
          if (target.previousSibling) {
            target.previousSibling.focus()
          }
          break
        case 'right':
          if (target.nextSibling) {
            target.nextSibling.focus()
          }
          break
        case 'ok':
          target.click()
          break
      }
    },
    inputKeyDown (e) {
      const key = keyMap[e.keyCode]
      switch (key) {
        case 'down':
          document.getElementById('buttonContent').children[0].focus()
          break
      }
    },
    returnFn (e) {
      const key = keyMap[e.keyCode]
      if (key === 'return') {
        e.stopPropagation()
        if (this.state === 0) {
          this.back()
          return
        }
        if (this.state === 1) {
          if (!this.isConnecting) {
            this.cancelConnect()
          }
          return
        }
        if (this.state === 2) {
          this.cancelConnect()
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$ime.close()
    next()
  }
}
</script>
<style lang="scss" scoped>
.wifisetingPage {
  height: 100%;
  .wifiTabContent {
    width: 65%;
    height: 60px;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    .back {
      display: block;
      width: 100px;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      text-align: center;
      color: #fff;
      border-radius: 20px;
      &:focus {
        background: gray;
      }
    }
    .wifiRefresh {
      width: 160px;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      text-align: center;
      color: #fff;
      border-radius: 20px;
      &:focus {
        background: gray;
      }
    }
  }
  .wifisettingBox {
    height: 100%;
    .wifiList {
      height: 100%;
      overflow-y: scroll;
      li {
        width: 100%;
        line-height: 50px;
        color: #fff;
        font-size: 30px;
        margin-bottom: 10px;
        border-bottom: 1px solid #fff;
        -webkit-user-select: none;
        font-size: 28px;
        &:focus {
          background: gray;
        }
        .wifiName {
          display: inline-block;
          width: 65%;
          text-align: left;
        }
        .isConnect {
          display: inline-block;
          width: 20%;
          text-align: center;
        }
        .wifiPng {
          vertical-align: text-bottom;
        }
      }
    }
  }
  .wifiInputBox {
    #wifiName {
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 30px;
      color: #fff;
    }

    #disConnect {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: #000;
      display: none;
    }

    #disConnectwifiName {
      margin: 100px 0;
      color: #fff;
      text-align: center;
    }

    #pwdInput {
      width: 600px;
      height: 60px;
      border: 1px solid #fff;
      border-radius: 20px;
      outline: none;
      display: block;
      margin: 20px auto;
      padding: 0 20px;
      font-size: 35px;
      line-height: 60px;
    }

    #buttonContent {
      display: -webkit-flex;
      -webkit-justify-content: space-around;
      align-items: center;
    }

    #buttonContent .btn {
      width: 140px;
      height: 40px;
      font-size: 25px;
      color: #fff;
      line-height: 40px;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 20px;
      margin: 15px 0px;
      border-radius: 20px;
      &:focus {
        background: gray;
      }
    }
  }
  #loadingBox {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 30px;
    p {
      line-height: 60px;
      font-size: 30px;
      text-align: center;
      margin-top: 100px;
      color: #fff;
    }
  }
  .scanLoading {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0px;
    left: 0px;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    img {
      display: block;
    }
  }
  .disConnect {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #000;
    #disConnectwifiName {
      margin: 100px 0;
      color: #fff;
      text-align: center;
    }
    .buttonContent {
      display: -webkit-flex;
      -webkit-justify-content: space-around;
      align-items: center;
      .btn {
        width: 140px;
        height: 40px;
        font-size: 25px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 20px;
        margin: 15px 0px;
        border-radius: 20px;
        &:focus {
          background: gray;
        }
      }
    }
  }
}
</style>
